import styled from "styled-components"

export const WrapperStyled = styled.div`
  max-width: ${({ width }) => width}px;
  width: 100%;
  margin: 60px auto;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 754px) {
    padding: 0 1rem;
    margin: 2rem auto;
  }
`
