import styled from "styled-components"

export const Photo = styled.img`
  max-height: 296px;
  max-width: 443px;
  width: 100%;
`

export const Text = styled.div`
  line-height: 1.5;
  text-align: justify;
  color: #5a6578;
  letter-spacing: 0.1px;
  margin-top: 3rem;
`

export const List = styled.ul`
  list-style: initial;
  padding-left: 30px;
`
