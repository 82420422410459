import React from "react"
import PropTypes from "prop-types"
import { WrapperStyled } from "./Wrapper.styled"

const Wrapper = ({ children, width }) => (
  <WrapperStyled width={width}>{children}</WrapperStyled>
)

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Wrapper
