import React from "react"

import Seo from "../../config-components/seo"
import photo from "../../../static/images/about/katamaran-relocation-ready-1.jpg"
import Layout from "../../layouts/MainLayout/Layout"
import Wrapper from "../../components/Wrapper/Wrapper"
import { List, Photo, Text } from "./PageAbout.styled"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import { Link } from "gatsby"

const PageAbout = ({ location }) => {
  return (
    <Layout>
      <Header page={location.pathname} />
      <Seo
        title="Велокатамараны и водные аттракционы - информация о нас"
        description="Водные велосипеды, надувные катамараны с педалями, водные батуты и другое - история компании и информация о производителе велокатамаранов MARINA-M."
        keywords="велокатамаран купить, водный батут, моторный катамаран"
      />
      <Wrapper width={754}>
        <Photo src={photo} alt="катамаран в сборе" title="катамаран в сборе" />
        <Text>
          <h1>Привет! Мы - молодая производственно-инжиниринговая компания.</h1>
          Сами разрабатываем и производим: <br />
          <List>
            <li>складные велокатамараны</li>
            <li>водные батуты</li>
            <li>моторные катамараны</li>
          </List>
          Наша цель - сделать отдых приятным и доступным (есть очень компактные
          и недорогие модели). <br />
          Мы на <Link to="/contacts">связи</Link> - готовы ответить на любые
          вопросы 🙂
        </Text>
      </Wrapper>
      <Footer />
    </Layout>
  )
}
export default PageAbout
